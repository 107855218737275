import ContactFormSection from '@/components/ContactFormSection';
import CooperationSection from '@/components/CooperationSection';
import HeroSection from '@/components/HeroSection';
import MainPageBannerSection from '@/components/MainPageBannerSection';
import OurOfferSection from '@/components/OurOfferSection';
import OurTeamSection from '@/components/OurTeamSection';
import PolicySection from '@/components/PolicySection';
import ReviewsSection from '@/components/ReviewsSection';
import Head from 'next/head';

export default function Home() {
  return (
    <>
      <Head>
        <title>SellWell Nieruchomości</title>
        <meta name="description" content="Strona główna firmy SellWell" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <HeroSection
        title={
          <>
            Skuteczna sprzedaż
            <br /> i kupno mieszkań, z{' '}
            <span className="font-bold">SellWell Nieruchomości</span>
          </>
        }
        desc="Oferujemy wsparcie na terenie Łodzi oraz całej Polski w sprzedaży, kupnie i wynajmie mieszkań oraz lokali. Zaufaj naszym licencjonowanym specjalistom, którzy wesprą Cię w całym procesie."
        image="/homepage-hero.png"
        hasCard
      />
      <CooperationSection />
      {/* <HomepageHeroSubsection /> */}
      <OurOfferSection />
      <MainPageBannerSection />
      <OurTeamSection />
      <PolicySection />
      <ReviewsSection />
      <ContactFormSection
        title={
          <div className="text-[35px] xl:text-[45px] font-bold uppercase text-black">
            Skontaktuj się
            <br /> z nami
          </div>
        }
        desc={
          <div className="text-[16px] text-[#959595]">
            Po wypełnieniu formularza zapewniamy, że skontaktujemy się z Tobą
            niezwłocznie, zwykle w ciągu 24 godzin.
          </div>
        }
      />
    </>
  );
}
